
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import { apiColorList } from "@/api/model.ts";
import { RequestPaging } from "@/utils/util";
import LsPagination from "@/components/ls-pagination.vue";

@Component({
  components: {
    LsPagination,
  },
})
export default class Dialog extends Vue {
  pager: RequestPaging = new RequestPaging();
  searchObj: any = {
    zh_cn_name: "",
    color: "",
  };
  visible = {
    val: false,
  };
  @Provide("visible")
  visibleVal = this.visible;
  // props
  @Prop({ default: "确认" }) confirmButtonText!: string; //确认按钮内容
  @Prop({ default: "取消" }) cancelButtonText!: string | boolean; //取消按钮内容
  @Prop({ default: false }) disabled!: boolean; //是否禁用
  @Prop({ default: false }) async!: boolean; //是否开启异步关闭
  @Prop({ default: true }) clickModalClose!: boolean; // 点击遮罩层关闭对话窗口
  apiColorList = apiColorList;
  // data

  // methods
  handleEvent(type: "cancel" | "confirm") {
    this.$emit(type);
    if (!this.async || type === "cancel") this.close();
  }

  onTrigger() {
    if (this.disabled) return;
    this.open();
  }

  close() {
    this.visible.val = false;
  }
  open() {
    this.visible.val = true;
    this.getList();
    this.$emit("open");
  }

  getList() {
    this.pager.request({
      callback: apiColorList,
      params: {
        ...this.searchObj,
      },
    });
  }

  handleChoose(row: any) {
    this.$emit("confirm", row);
    this.close();
  }
  created() {
    this.getList();
  }
  list: any[] = [];
  data() {
    return {
      list: [],
    };
  }
}
