import { render, staticRenderFns } from "./add-price.vue?vue&type=template&id=79e94fa2&scoped=true"
import script from "./add-price.vue?vue&type=script&lang=ts"
export * from "./add-price.vue?vue&type=script&lang=ts"
import style0 from "./add-price.vue?vue&type=style&index=0&id=79e94fa2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e94fa2",
  null
  
)

export default component.exports