
import { Component, Prop, Watch, Provide, Vue } from "vue-property-decorator";
import { apiModelDesignPartsLists } from "@/api/model";
@Component
export default class Dialog extends Vue {
  visible = {
    val: false,
  };
  @Prop() value: any;
  @Provide("visible")
  visibleVal = this.visible;
  // props
  @Prop({ default: "" }) title!: string; //弹窗标题
  @Prop({ default: "确认" }) confirmButtonText!: string; //确认按钮内容
  @Prop({ default: "取消" }) cancelButtonText!: string | boolean; //取消按钮内容
  @Prop({ default: false }) disabled!: boolean; //是否禁用
  @Prop({ default: false }) async!: boolean; //是否开启异步关闭
  @Prop({ default: true }) clickModalClose!: boolean; // 点击遮罩层关闭对话窗口.
  @Prop({ default: 0 }) model_id!: number;

  // data
  others: any = [];
  typeList = [
    { label: "二维码", value: "1" },
    { label: "条形码", value: "2" },
    { label: "文本", value: "3" },
    { label: "裁剪", value: "4" },
  ];
  img_datas: any = [
    {
      name: "",
      angle: "",
      imgNW: "",
      imgNH: "",
    },
  ];
  fast_img_data: any = [
    {
      name: "",
      angle: "",
      imgNW: "",
      imgNH: "",
    },
  ];
  otherData: any = {
    bind_action: 0,
    bind_parts: "",
    dpi: "72",
    img_suffix: "jpeg",
    others: [],
    img_data: [],
    factory_svg: "",
    fast_img_data: [],
    fast_factory_svg: "",
  };

  actionTypeList = [
    { label: "无", value: 0 },
    { label: "改颜色", value: 1 },
  ];
  designPartsList: any = [];
  designParts = [];

  addOthersData() {
    this.others.push({
      type: "",
      left: 0,
      top: 0,
      width: "",
      height: "",
      angle: 0,
      content: "",
      fontsize: 0,
      fill: "",
      background: "",
    });
  }

  delOthersData(index: number) {
    this.others.splice(index, 1);
  }
  // methods
  handleEvent(type: "cancel" | "confirm") {
    if (!this.async || type === "cancel") this.close();
    if (type === "confirm") {
      this.otherData.img_data = this.img_datas;
      this.otherData.fast_img_data = this.fast_img_data;
      this.otherData.bind_parts = this.designParts.join(",");
      this.otherData.others = this.others;
      this.$emit("confirm", this.otherData);
    }
  }

  onTrigger() {
    if (this.disabled) return;
    this.open();
  }

  close() {
    this.visible.val = false;
    this.$emit("close");
  }
  open() {
    this.otherData.dpi = this.value.dpi || 72;
    this.otherData.img_suffix = this.value.img_suffix || "jpeg";
    this.otherData.img_data = this.img_datas = this.value.img_data;
    this.otherData.fast_img_data = this.fast_img_data =
      this.value.fast_img_data;
    this.otherData.factory_svg = this.value.factory_svg;
    this.otherData.fast_factory_svg = this.value.fast_factory_svg;
    this.otherData.bind_action = this.value.bind_action;
    this.designParts = this.value.bind_parts.split(",");
    if (this.value.others) {
      this.others = this.value.others;
    }
    this.visible.val = true;
    this.getDesignParts();
    this.$emit("open");
  }

  getDesignParts() {
    apiModelDesignPartsLists({ model_id: this.model_id }).then((res: any) => {
      this.designPartsList = res.lists;
    });
  }
}
